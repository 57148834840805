@import "../../Sass/variables";

.ds-inputs.ds-ctxt-input {
  min-width: 260px;

  .ds-inputs.ds-txt-input {
    width: 100%;

    input {
      width: 100%;
      border-radius: 0 0 10px 10px;
    }
  }

  .text-menu {
    display: flex;
    position: relative;

    .m-inputs:last-child .ds-inputs {
      border-radius: 0 10px 0 0;
    }

    .gr-10 {
      flex-grow: 10;
    }

    .gr-20 {
      flex-grow: 20;
    }

    .block-picker {
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4) !important;
      position: absolute !important;
      z-index: 50;
      top: 40px;
      left: -65px;
    }

    .ds-inputs.ds-drop-down {
      border-bottom: none;
      width: 100%;
    }

    .ds-inputs.ds-i-button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: none;

      .color-icon {
        width: 35px;
        height: 23px;
      }
    }

    .ds-inputs.ds-number {
      width: 100%;
      text-align: left;
      min-width: 20px;
      position: relative;
      border: none;
      border-top: 1px solid rgba(10, 67, 86, 0.5);

      .icons {
        position: absolute;
        right: 0;
        height: 15px;
        width: 15px;
        margin: 0 2px 0 0;

        &.minus {
          bottom: 2px;
        }

        &.plus {
          top: 2px;
        }
      }

      input {
        width: 32px;
        border: 0;
        padding: 2px;
      }
    }
  }
}
