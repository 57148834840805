.banner-menu-container {
  position: absolute;
  right: 0;
  z-index: 20;
  height: 93%;
  background: #e9e9e9;
  -webkit-clip-path: inset(0px 0px 0px -5px);
  clip-path: inset(0px 0px -5px -5px);
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  width: fit-content;
  justify-self: end;

  .menu-list-item {
    width: 15vw;
    height: 100%;
    overflow-y: scroll;
    // margin-top: 5vw;

    .input-section {
      padding: 15px 20px 0;
      text-align: left;
      color: #0a4356;

      &:last-of-type {
        padding-bottom: 100px;
      }

      .block-picker div:nth-child(2) {
        height: 50px !important;
      }

      .title {
        font-size: 1.3rem;
        font-weight: 400;
      }

      .ds-inputs {
        &.ds-drop-down {
          color: #0a4356;
          border: 1px solid rgba(26, 67, 86, 0.5);
        }

        &.ds-i-input input {
          border-color: rgba(26, 67, 86, 0.5);
          width: 135px;
        }

        &.ds-number {
          width: 170px;
          color: #0a4356;
          border: 1px solid rgba(26, 67, 86, 0.5);
        }

        &.ds-slider {
          width: 170px;
        }
      }

      .icon {
        height: 35px;
        width: 40px;
        border: 1px solid rgba(26, 67, 86, 0.5);
        padding: 5px 0;
        color: #0a4356;
        cursor: pointer;
        background-color: #fff;
        box-sizing: border-box;
        fill: currentColor;
        margin-top: -5px;

        &:hover {
          color: #4da4d4;
        }

        &:hover.disable-hover {
          color: inherit;
        }

        &.border-right-none {
          border-right: none;
        }
      }

      .ds-block-picker {
        position: relative;
        margin-top: 15px;

        .custom-triangle {
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 0px 10px 10px;
          border-color: transparent transparent rgb(255, 255, 255);
          position: absolute;
          top: -10px;
          left: 20px;
          margin-left: -10px;
        }
      }
    }

    .menu-item-inputs {
      color: #ffffff;
      background: #23a4ce;
    }
  }
}
