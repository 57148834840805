.creative-redirection-menu-container {
  height: calc(100% - 2vw);
  margin: 1vw 0;
  overflow-y: overlay;
  padding: 1.5vw 0;
}

.creative-redirection-menu {
  height: 100%;
  margin: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 28vw;
  gap: 1.381vw;
}

@media screen and (min-width: 1440px) {
  .creative-redirection-menu {
    width: 25.403vw;
  }
}