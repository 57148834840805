@import "../../Sass/variables";

.ds-shape{
    background-size: cover;
    background-position: center;
}

.square {
    width: 100px;
    height: 100px;
}

.rectangle {
    width: 200px;
    height: 100px;
}

.circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.oval {
    width: 200px;
    height: 100px;
    border-radius: 100px / 50px;
}

.triangle {
    width: 0px;
    height: 0px;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 100px solid $base-color;
}

.trapezoid {
    border-bottom: 100px solid $base-color;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    height: 0;
    width: 100px;
}

.parallelogram {
    width: 150px;
    height: 100px;
    transform: skew(20deg);
}

.egg{
    width: 126px;
    height: 180px;
    background-color: red;
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
}