.ds-page {
  height: 100%;
  width: 100%;
}

// .grid-content {
  //   grid-row-start: 2;
  //   grid-row-end: 5;
  //   grid-column-start: 2;
  //   grid-column-end: 6;
  // }
  
.creative-page {
  background: rgba(211, 211, 211, 1);
  // display: grid;
  // grid-template-columns: repeat(6, 1fr);
  // grid-template-rows: repeat(5, 1fr);
  height: calc(100vh - 70px);
  overflow: hidden;
  position: relative;
  flex-direction: row;

  & .creative-page-container {
    width: 35.347vw;
    background-color: white;
    display: grid;
    grid-template-rows: 3vw auto 3vw;
  }

  // @media screen and (min-width: 1920px) {
  //   & .creative-page-container {
  //     width: 100%;
  //   }
  // }

  & .creative-preview-container {
    width: 64.653vw;
    background-color: #f1f6f9;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 3vw;
  }
}

// .grid-content {
//   grid-row-start: 2;
//   grid-row-end: 5;
//   grid-column-start: 2;
//   grid-column-end: 6;
// }

.banner-display-container {
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-page,
.creative-management-page {
  padding: 2.5vw 0 10vw 0;
  gap: 4vw;
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: center;

  .action-bar {
    width: 100%;
    // height: 35px;
    display: flex;
    box-sizing: content-box;
    flex-direction: column;
    gap: 1vw;
    align-items: center;

    .btns-container {
      display: flex;
      gap: 5vw;
    }

    .new-creative-btn-container {
      width: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
    }

    .new-creative-btn {
      height: 3vw;
      width: 3vw;
      color: rgb(99, 200, 243);
    }

    .new-creative-text {
      font-weight: bold;
      margin: 0;
      font-size: 1vw;
    }

    .ds-button {
      margin-right: auto;
    }

    .action-bar-carousel {
      .rec .rec-carousel {
        width: 95%;
      }
      .rec-carousel-item-0 {
        margin-left: 1vw;
      }
      .rec-dot_active {
        background-color: #006eb580;
      }
      .rec-dot:hover,
      .rec-dot:focus,
      .rec-dot:not(:focus-within) {
        box-shadow: 0 0 1px 3px #3a88b7;
      }
      .rec-arrow {
        background-color: #23a4ce24;
      }
      .rec-arrow:hover,
      .rec-arrow:focus {
        color: #23a4ce;
      }
    }
  }
}

.full-page-grid {
  padding: 2.5vw 0;

  .grid {
    height: calc(100vh - 15vw) ;
  }
}

.abort_container {
  display: flex;
  justify-content: flex-end;
  width: 100vw;
  height: fit-content;
  z-index: 99999999999999;
}

.abort_btn {
  height: 3vw;
  width: 3vw;
}