html {
  height: 100%;
}

body {
  min-height: 100%;
  /* min-width: 1560px; */
  margin: 0;
  /* -font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  padding: 0 !important;
}

* {
  font-family: "Lexend", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rounded-5 {
  border-radius: 5px;
}
.rounded-10 {
  border-radius: 10px;
}
.rounded-15 {
  border-radius: 15px;
}
.rounded-20 {
  border-radius: 20px;
}
.rounded-40 {
  border-radius: 40px;
}

.MuiBackdrop-root {
  background-color: #0e184e73 !important;
}

.MuiBackdrop-invisible {
  background-color: transparent !important;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background: #9bd4f5;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #83d1ff;
}
