@import "../../Sass/variables";

.general-menu-container {
  height: calc(100% - 2vw);
  margin: 1vw 0;
  overflow-y: overlay;
}

.general-menu {
  width: 25.403vw;
  margin: auto;
  padding: 1.5vw 0;
}

.pop-up-container {
  position: absolute;
  top: 0;
  left: 0;
  // background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  z-index: 10000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pop-up {
  flex-flow: column;
  display: flex;
  padding: 15px 20px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);

  header {
    font-size: 23px;
    font-weight: 600;
    color: #303030;
  }

  .text-msg {
    color: #303030;
    margin: 5px 0 15px;
    font-size: 17px;
    text-align: left;
  }

  .close-btn {
    position: relative;
    left: calc(100% - 25px);
    height: 25px;
    width: 25px;
    color: #0a4356;
    cursor: pointer;
  }

  .form {
    padding: 1vw 0.5vw 1.5vw 0.5vw;
    display: flex;
    text-align: left;
    flex-direction: column;
    row-gap: 1vw;

    .input-section {
      width: 15vw;

      &:last-of-type {
        margin-bottom: 10px;
      }

      .label {
        display: block;
        text-align: left;
        font-size: 19px;
        margin-bottom: 2px;
        color: $base-color;
      }

      .input {
        outline: none;
        width: 100%;
        border: 1px solid rgba($base-color, 0.5);
        padding: 8px 10px;
        border-radius: 10px;
        color: $base-color;
        height: auto;

        &.error {
          border-color: $error-color;
        }
      }
    }
  }

  &.banner-preview {
    max-width: 100vw;
    padding: 0;
    border-radius: 0;
  }

  &.new-creative {
    .ds-inputs.ds-button button,
    .ds-inputs.ds-button button:hover {
      border: 1px solid rgba(0, 0, 0, 0);
    }
  }

  &.shape {
    padding: 0;
    max-width: 98%;

    header,
    footer {
      padding: 10px 20px;
    }

    footer {
      text-align: right;
    }

    .content {
      height: 400px;
      width: 690px;
      display: flex;

      .box,
      .menu {
        height: 100%;
      }

      .menu {
        width: 180px;
        padding: 10px;
        border-right: solid 1px $pop-up-inner-border;

        .block-picker {
          > div:nth-child(2) {
            height: 50px !important;
          }

          > div:nth-child(3) > div > span > div {
            margin: 0 7px 7px 0 !important;
          }
        }

        .ds-inputs.ds-image {
          text-align: initial;
          margin-top: 15px;
          width: 100%;
        }

        .ds-inputs.ds-slider {
          margin-top: 20px;
          width: 100%;
        }
      }

      .box {
        width: calc(100% - 180px);
        -justify-content: space-evenly;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        display: flex;
        padding: 10px;

        .ds-shape {
          margin: 10px 15px;
          opacity: 0.8;
          cursor: pointer;

          &:hover {
            opacity: 0.9;
          }

          &.selected {
            opacity: 1;
          }
        }
      }
    }
  }
}

#dialog-root {
  width: 100%;
  height: 100%;
}

.rdw-dropdown-wrapper .rdw-dropdown-selectedtext {
  color: black;
  text-decoration: none;
}

.rdw-dropdown-wrapper.rdw-fontsize-dropdown .rdw-dropdown-selectedtext {
  width: 3vw;
}

.generate-tag-email-chip {
  width: 30% !important;
}

// .rdw-embedded-modal {
//   height: unset !important;
// }
