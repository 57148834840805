@import "../../Sass/variables";

.creative-menu-container {
  height: calc(100% - 2vw);
  margin: 1vw 0;
  overflow-y: overlay;
}

.creative-menu-container:focus-visible {
  border: 0;
  outline: 0;
}

.side-menu {
  width: 28vw;
  margin: auto;

  .hide-border-right {
    border-right: none;
  }
}

@media screen and (min-width: 1440px) {
  .side-menu {
    width: 25.403vw;
  }
}

.side-menu-btn svg {
  width: 1.5vw;
  height: 1.5vw;
}

.side-menu-btn svg :hover {
  color: #0a4356;
}

.side-menu-arrow-btn {
  width: fit-content;
  position: absolute !important;
  right: 0;
  top: 50%;
  svg {
    width: 2vw;
    height: 2vw;
    color: black;
  }
}

.input-box {
  display: flex;
  column-gap: 0.5vw;
  padding: 10px;
  text-align: left;

  .ds-inputs.ds-i-input {
    // margin-left: 0.5vw;
    // border-radius: 10px;
  }

  .ds-inputs.ds-i-input input {
    width: 5vw;
  }
}

.avatar-box {
  text-align: left;
  padding: 1.5vw 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 0.556vw;
  // width: 28vw;
  // margin: auto;

  .avatar-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .img-type {
      font-size: 0.7vw;
      margin: 0;
    }
  }

  .avatar-action-box {
    height: 90px;
    width: 90px;
    margin: 4px 4px 0 0;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    background-color: #9BD4F5;
    border-radius: 0.694vw;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    &:nth-child(-n + 3) {
      margin-top: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    .text-display {
      top: 0;
      left: 0;
      position: absolute;
      padding: 6px;
      line-height: 14px;
      font-size: 14px;
      font-weight: 600;
      font-style: italic;
      color: #0a4356;
    }

    .ds-shape {
      transform: translate(-50%, -50%) scale(0.5);
      position: absolute;
      top: 50%;
      left: 50%;
    }

    .hover-menu {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;

      &:hover {
        .right-corner {
          left: 0;
        }
      }

      .right-corner {
        display: flex;
        flex-direction: column;
        row-gap: 0.5vw;
        height: 100%;
        width: 30px;
        padding-top: 5px;
        left: -40px;
        top: 0;
        position: absolute;
        transition: left 0.3s ease-in;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 5px -1px 5px 2px rgba(0, 0, 0, 0.3);

        .icon {
          height: 20px;
          width: 20px;
          margin: 2px 5px;
          cursor: pointer;
          color: #555555;
        }
      }
    }

    &.img-placeholder {
      height: 7vw;
      width: 7vw;
      margin: 4px;
      border: 1px solid #9BD4F5;

      &.divider {

        .ds-inputs.ds-image {
          background-color: rgba(211, 211, 211, 1);
          background-size: 4vw;
        }
      }

      & .title {
        // padding: 0.1vw;
      }

      &:hover {
        // transform: scale(0.9);

        .title {
          // background-color: rgba($base-color, 1);
        }

        .ds-inputs.ds-image svg {
          color: rgba($base-color, 1);
        }
      }

      .title {
        font-size: 0.65vw;
        text-align: center;
        // background-color: rgba($base-color, 0.7);
        color: #fff;
        // position: absolute;
        width: 100%;
        // bottom: 0;
        // left: 0;
      }

      .ds-inputs.ds-image {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        border: none;

        svg {
          background-color: transparent;
          color: rgba($base-color, 0.8);
          cursor: pointer;
        }
      }

      &:nth-child(-n + 3) {
        margin: 4px;
      }

      &:nth-child(3n) {
        margin: 4px;
      }
    }
  }
}

// @media screen and (min-width: 1440px) {
//   .avatar-box {
//     width: 25.403vw;
//   }
// }