.grid {
  height: calc(100vh - 20vw);
  /* position: relative; */
  width: 90%;
  /* padding: 1vw; */
  border-radius: 15px;
  box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px,
    rgb(145 158 171 / 12%) 0px 12px 24px -4px;
  background: white;
}

.grid .ds-loader {
  background: rgba(255, 255, 255, 1);
  left: 21px;
  top: 180px;
  width: calc(100% - 42px);
  height: calc(100% - 230px);
}

.grid .MuiDataGrid-root {
  color: black;
  height: 100%;
  border: none;
}

.MuiDataGrid-toolbarContainer .MuiButton-root {
  color: white;
}

.grid .MuiTablePagination-displayedRows {
  margin-top: 16px;
}

.grid .MuiTablePagination-selectLabel {
  margin-top: 10px;
  color: red;
}

.grid .MuiDataGrid-columnHeadersInner {
  border-radius: 10px 10px 0 0;
  /* background: #23a4ce;
  color: #fff; */
  font-size: 16px;
}

.grid .MuiDataGrid-columnHeader:last-of-type .MuiDataGrid-columnSeparator {
  display: none;
}

.grid .grid-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 4px 4px 2px -1px rgb(0, 0, 0, 0.1);
  background-color: #fff;
  font-weight: normal;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  padding: 8px 0;
  border-radius: 0;
  min-width: 120px;
  box-sizing: border-box;
  z-index: 100000000;
  text-align: left;
  position: absolute;
}

.grid .grid-menu li,
.MuiDataGrid-menuList {
  color: #23a4ce;
  font-size: 14px;
}

.grid .grid-menu .icon {
  margin-right: 10px;
  margin-top: -2px;
}

/* .MuiIconButton-sizeSmall {
  color: white !important;
} */
