@import "../../Sass/variables";

.loader-page, .error-page{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    background: linear-gradient($highlight-color, $base-color);

    .loader{
        height: 150px;
        margin-top: -75px;
    }

}

.error-page{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    flex-direction: column;
    justify-content: normal;
    color:#fff;
    z-index: 100000;

        h1{
            font-size: 8em;
            text-shadow: 4px 4px 3px $base-color;
        }

        p{
            color: #265f72;
            font-size: 1.8em;
            max-width: 600px;
            padding: 0 20px;
            text-shadow: 1px 1px 1px $base-color;
        }

        .loader{
            height: 100px;
            position: absolute;
            top: 0;
            left: 0;
            margin: 20px;
        }
}

.login-page{
    position: absolute;
    z-index: 100000;
    top: 0;
    left: 0;
    height: 100%;
    width:100%;
    display: flex;
    justify-content: center;
    background: linear-gradient(0deg, $base-color 70%, $highlight-color 30%);

    .logo{
        width: 110px;
        position: absolute;
        top: 40px;
        left: calc(50% - 270px);
    }

    .form-box{
        position: relative;
        display:inline-block;
        min-height: 200px;
        height: fit-content;
        min-width: 400px;
        top: 30%;
        background-color:rgba(255,255,255, 1);
        border-radius: 0 0 8px 8px;

        .tab{
            position:relative;

            .title{
                display: inline-block;
                width: 50%;
                height: 50px;
                font-size: 25px;
                text-align: center;
                line-height: 50px;
                position: absolute;
                top: -50px;
                color: #fff;
                background-color:rgba(255,255,255,0.1);

                &.selected{
                    background-color:rgba(255,255,255,1);
                    color:$base-color;
                }

                &.login{
                    left:0;
                    border-radius: 8px 8px 0 0;
                }

                &.signup{
                    display:none;
                    right:0;
                    border-radius: 0 8px 0 0;
                }

            }

        }

        .form{
            padding: 30px;
            height: 100%;
            width: 100%;

            .input {
                margin-bottom: 20px;

                .name{
                    display: block;
                    text-align: left;
                    font-size: 17px;
                    color: $base-color;
                }

                input{
                    outline:none;
                    width:100%;
                    border:1px solid $base-color;
                    padding: 8px 10px;
                    border-radius: 3px;


                    &[type=checkbox]{
                        width: auto;
                        position: relative;
                        top: 1px;
                        margin-right: 5px;
                    }

                    &.error{
                        border-color:$error-color;
                    }


                }

                &.remember{
                    margin-bottom: 10px;
                }
            }

            .ds-inputs.ds-button{
                width: 100%;
                height: 40px;
                text-align: left;

                button{
                    width: 140px;
                    font-size: 20px;
                }

            }

        }

    }
}