.carousel-btn-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  cursor: pointer;
  gap: 1vw;

  .carousel-btn-image-container {
    background-image: url(../../utils/images/creative_images/mobile_frame.png);
    background-repeat: no-repeat;
    background-size: contain;
    // width: 96px;
    // height: 192px;
    width: 5vw;
    height: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;

    // .carousel-btn-iframe {
    //   width: 75px;
    //   height: 130px;
    // }
    
    .carousel-btn-image {
      object-fit: contain;
      width: 85%;
      pointer-events: none;
    }

    .wide-creative {
      height: 40px;
    }
  }

  .carousel-btn {
    pointer-events: none;
    height: 6vw;
  }

  .carousel-text {
    margin: 0;
    font-weight: bold;
    font-size: 1vw;
  }

  &:focus-visible {
    outline: none;
  }
}

.rec-pagination {
  margin-top: 10px !important;
}
