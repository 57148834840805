input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ds-mui-inputs {
  & label {
    color: black;
  }

  // & fieldset {
  //   border-color: black;
  // }
}

// .ds-date-time-input {
//   & fieldset {
//     border: 1px solid #35A7E0;
//     border-radius: 10vw;
//   }

//   & input {
//     font-size: 1vw;
//     padding: 0.6vw 1.2vw;
//     height: 2.083vw;
//     font-family: "Lexend";
//   }

//   & .MuiOutlinedInput-root {
//     padding-right: 1.2vw
//   }
// }

.error::placeholder {
  color: red;
}
