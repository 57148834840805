.item-container{
    width: 0;
    height: 0;
    position: relative;
    top: 0;
    left: 0;
}

.item-container .image{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.item-container .text{
    width: max-content;
}

.item-container[data-focus="true"] .moveable-control-box {
    --moveable-color: #90ee90;
}


