$input-width: 200px;

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    background-color: #fff;
    box-shadow: 0 4px 28px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      font-size: 23px;
      font-weight: 600;
      color: #303030;
      text-align: center;
      padding: 15px 20px 10px 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .action-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      padding: 15px 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.2);

      .back-btn {
        margin-right: 20px;
      }
    }

    .form {
      padding: 0 1.5vw 2vw;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      height: 35vw;
      row-gap: 1vw;

      .input-section {
        // padding-top: 10px;

        .title {
          font-size: 1.5vw;
          color: #0a4356;
        }
      }

      .image-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .input-section {
          &:last-of-type {
            padding-right: 0;
          }

          .ds-inputs.ds-image {
            width: 8vw;
            height: 8vw;
          }
        }
      }

      .text-section {
        display: flex;
        flex-direction: column;
        row-gap: 1.5vw;

        // .input-section {
        //   &.texts:nth-child(odd) {
        //     padding-right: 20px;
        //   }
        // }
      }

      .ds-inputs.ds-ctxt-input {
        width: 30vw;
      }

      .ds-inputs.ds-image {
        width: $input-width;
      }

      .ds-inputs.ds-i-button {
        margin-left: 0;

        &:first-child {
          border-radius: 10px 0 0 0;
        }
      }

      .ds-inputs.ds-txt-input input {
        outline: none;
        border: 1px solid rgba(10, 67, 86, 0.5);
        padding: 8px 10px;
        color: #0a4356;
        height: auto;
      }
    }
  }
}

/*
    outline: none;
    width: 100%;
    border: 1px solid #0A4356;
    padding: 8px 10px;
    border-radius: 3px;

        outline: none;
        width: 100%;
        border: 1px solid rgba(10, 67, 86, 0.5);
        padding: 8px 10px;
        border-radius: 3px;
        color: #0A4356;
        height: auto;


    */
