@import "../../Sass/variables";

.page-header,
.page-footer {
  height: 3vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.page-footer {
  & .full-width-btn {
    width: 100%;
    padding: 0;
    border-radius: 0;
  }
}

.header-logo {
  & img {
    object-fit: contain;
    height: initial;
  }
}

.page-header {
  align-items: center;
  background-color: $highlight-color;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0 10px;

  .dolphinsoft-logo {
    height: 35px;
    margin: 15px 15px 15px 5px;
    cursor: pointer;

    @media screen and (min-height: 1000px) {
      height: 4vh;
    }
  }

  .header-title {
    font-size: 2vw;
    color: white;
    margin: 0;
  }

  .user-container {
    margin-left: auto;
    margin-right: 0.5vw;
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 10000;
    height: 100%;

    .user-icon {
      color: $base-color;
      width: 2.5vw;
      height: 2.5vw;
      margin: 0 0.1vw 0 0.2vw;
      padding: 6px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border: 1px solid #fff;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.3);

      @media screen and (min-height: 1000px) {
        width: 4vh;
        height: 4vh;
      }
    }

    .user-name {
      font-size: 1.5vw;
      margin-right: 0.5vw;
    }

    &:hover .user-menu {
      display: block;
    }

    .user-menu {
      position: absolute;
      top: 7vh;
      background-color: #23a4ce;
      right: 0;
      border-top: 1px solid #fff;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
      display: none;

      @media screen and (min-width: 1279px) {
        top: 7.5vh;
      }

      @media screen and (width: 1280px) and (height: 1024px) {
        top: 7vh;
      }

      @media screen and (min-width: 1679px) {
        top: 7vh;
      }

      .triangle {
        position: absolute;
        top: -1vh;
        right: 1.4vw;
        width: 0;
        height: 0;
        border-left: 0.5vw solid transparent;
        border-right: 0.5vw solid transparent;
        border-bottom: 1vh solid #fff;

        @media screen and (min-width: 1679px) {
          right: 1vw;
        }

        @media screen and (min-width: 2549px) {
          right: 0.8vw;
        }
      }

      .icon {
        margin-right: 10px;
      }

      li {
        font-size: 14px;
        padding: 5px 15px 5px 10px;
      }
    }
  }

  .log-out-btn {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .log-out-btn:hover {
    color: #fff;
  }
}

.ds-loader {
  position: absolute;
  z-index: 9998;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.inline {
    z-index: 1000;

    svg {
      width: 3.75em;
      animation: 1.5s spin ease infinite;
    }

    .ring {
      fill: none;
      stroke: rgba($highlight-color, 0.7);
      stroke-width: 2;
    }

    .ball {
      fill: $highlight-color;
      stroke: none;
    }
  }

  &.page {
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;

    .icon {
      position: relative;
      width: 2em;
      height: 2em;
      border: 3px solid $highlight-color;
      overflow: hidden;
      animation: spin2 2s ease infinite;
    }

    .icon::before {
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      width: 2em;
      height: 2em;
      background-color: rgba($highlight-color, 0.75);
      transform-origin: center bottom;
      transform: scaleY(1);
      animation: fill 2s linear infinite;
    }
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes spin2 {
    50%,
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes fill {
    25%,
    50% {
      transform: scaleY(0);
    }

    100% {
      transform: scaleY(1);
    }
  }
}
