@font-face {
  font-family: Heebo;
  src: url(../../Fonts/Heebo-Regular.ttf);
}

* {
  font-family: Heebo;
}

.mr-3 {
  margin-right: 3vw !important;
}

.banner-display-container {
  // width: calc(100% - 530px);
  // margin-left: 26vw;
  justify-self: center;
  align-self: baseline;
  position: relative;

  &.interstitial-border {
    background-image: url(../utils/images/iphone_frame.png);
  }

  .banner-display-frame-image {
    object-fit: contain;
    position: absolute;
    top: 0;
    z-index: 1000;
    width: 50%;
    content: url(../utils/images/phone-front-camera.png);
  }

  .box-shadow {
    box-shadow: 5px 5px 5px -4px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.3);
    // margin-top: 34px;
    display: inline-block;
    position: relative;
  }

  .target {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    height: 45vw;
    width: 67vw;
    margin: 0 auto;
    font-family: initial;

    .display-images {
      width: 50%;
      height: 100%;
      display: inline-block;
      background-repeat: no-repeat;
      background-size: cover;

      &.right {
        background-position: right;
      }
    }

    .divider {
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      transform: translateX(-50%);
      pointer-events: none;
    }
  }
}

.banner-menu {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: top 1s;
  // height: 100%;
  overflow-y: auto;

  .creative-menu-arrow-btn {
    width: fit-content;
    svg {
      width: 2vw;
      height: 2vw;
      color: black;
    }
  }
}

.banner-menu-inner {
  height: 100%;
  margin: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30vw;
  gap: 16px;

  .ds-inputs,
  .ds-inputs input,
  .ds-inputs .icon-holder {
    border-color: rgba(0, 0, 0, 0.3);
    color: #888888;
  }
}

@media screen and (min-width: 1440px) {
  .banner-menu-inner {
    width: 27.403vw;
  }
}

.bottom-menu-container {
  position: absolute;
  transition: bottom 1s;
  background-color: white;
  width: 79%;
  right: 0;
  display: flex;
  height: 4.5vw;
  z-index: 98;
  align-items: center;
  justify-content: center;
  bottom: -1vw;
  border: 1px solid #ebecf0;
}

.bottom-menu-open {
  bottom: 3vw;
}

.bottom-menu-breakpoints-container {
  width: 100%;
  display: flex;
  gap: 1vw;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5vw 1vw;
}

.bottom-menu-breakpoints-play-btn {
  width: 3vw;
  height: 3vw;
  border: 1px solid #ebecf0 !important;
  border-radius: 50% !important;
  box-shadow: 0 0 10px #ebecf0;
}

.bottom-menu-breakpoints-play-btn svg {
  width: 1.2em;
  height: 1.2em;
  color: black;
}

.bottom-menu-break-point {
  height: 3vw;
  width: 5vw;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5vw;
  border: 1px solid #ebecf0;
  border-radius: 0.3vw;
  cursor: pointer;
}

.bottom-menu-break-point:last-child {
  background-color: #f2f3f5;
}

.bottom-menu-break-point:hover {
  background-color: #0000000a;
}

.bottom-menu-break-point:last-child:hover {
  background-color: #e1e4e7;
}

.bottom-menu-break-point-inner {
  position: relative;
}

.bottom-menu-break-point-inner .bottom-menu-break-point-options-icon {
  position: absolute;
  top: -0.5vw;
  right: -0.5vw;
}

.bottom-menu-break-point-inner .bottom-menu-break-point-options-icon svg {
  width: 0.8em;
  height: 0.8em;
}

.bottom-menu-break-point-inner svg.bottom-menu-break-point-add-icon {
  width: 1.5em;
  height: 1.5em;
}

.bottom-menu-break-point-image {
  object-fit: cover;
  width: 100%;
}

.ds-layout {
  width: 100%;
  display: flex;
  margin-bottom: 4px;
  flex-wrap: wrap;
}

.align-right {
  margin-left: auto;
}

.button-group {
  align-items: center;
  display: flex;
  column-gap: 0.5vw;
  margin: 0;
  -overflow: hidden;
  vertical-align: bottom;
  padding: 0.5rem;

  &.grow-90 {
    flex-grow: 90;
    padding: 0;
  }

  &.left-flex {
    flex-grow: 2;
    display: flex;
    justify-content: flex-end;
  }

  .ds-inputs {
    // border-right-color: transparent;

    // input {
    //     border-right-color: transparent;
    // }

    &:last-child {
      border-right-color: rgba(0, 0, 0, 0.3);

      input {
        border-right-color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  &.align-right {
    text-align: right;
    margin-left: auto;
  }

  &.center {
    margin: 0 30px;
  }

  .ds-drop-down {
    vertical-align: bottom;
  }
}

.banner-menu .ds-drop-down,
.banner-menu .ds-i-button,
.banner-menu .ds-i-input {
  color: #888888;
}

.banner-menu {
  padding: 1.5vw 0;

  // & .MuiAutocomplete-root {
  //   width: 13vw;
  // }

  // & .MuiOutlinedInput-root {
  //   width: 13vw;
  // }
}

.banner-menu .space-left {
  margin-left: 30px;
}

.banner-menu .hide-border-right,
.banner-menu .hide-border-right.ds-i-input input,
.banner-menu .hide-border-right.ds-inputs input {
  border-right: none;
}

.banner-menu .hide-border-left.ds-i-input input {
  border-left: none;
}

.banner-menu .item {
  height: 2vw;
  width: 2.1vw;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 0.3vw;
  color: #888888;
  cursor: pointer;
  background-color: #fff;
  outline: none;
}

.banner-menu .color-icon {
  border: none;
  height: 100%;
  width: 100%;
  padding: 0.3vw;
  outline: none;
}

.active {
  color: rgba(10, 67, 86, 1) !important;
  // border-color: rgba(10, 67, 86, 1) !important;
}

.banner-menu .item:hover,
.banner-menu .ds-inputs.ds-i-button:hover,
.banner-menu .ds-inputs.ds-i-input .icon-holder:hover {
  /* color: rgba(47,163,213,1); light blue*/
  color: rgba(10, 67, 86, 1);
}

// .banner-menu .item:first-of-type {
//     border-left: 1px solid rgba(0, 0, 0, 0.3);
// }

// .banner-menu .item:last-of-type {
//     border-right: 1px solid rgba(0, 0, 0, 0.3);
// }

.banner-menu .block-picker {
  width: 106px !important;
  background: rgb(233, 233, 233) !important;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4) !important;
  position: absolute !important;
  z-index: 700;
  top: 82px;
  left: 208px;
}

.banner-context-menu {
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 4px 4px 2px -1px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  font-weight: normal;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 15px;
  padding: 8px 0;
  border-radius: 0;
  min-width: 120px;
  box-sizing: border-box;
  z-index: 100000000;
  text-align: left;
  position: absolute;
}

.banner-context-menu .context-menu-item {
  cursor: pointer;
  position: relative;
  height: 30px;
  line-height: 30px;
  padding: 0 16px;
}

.banner-context-menu .context-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

.banner-context-menu .context-menu-item .icon {
  color: #626262;
  margin-right: 10px;
  margin-bottom: 5px;
  height: 20px;
  width: 20px;
}

.ds-inputs.ds-i-button {
  margin-left: 0 !important;
}

// .button-group .ds-inputs:first-of-type,
// .button-group .item:first-of-type {
//   margin-left: 0 !important;
// }

// .button-group:only-child {
//   margin-left: 0 !important;
// }

// .button-group:not(:first-child) {
//   margin-left: 0.5vw;
// }

#ds_div_buttons {
  position: absolute;
  z-index: 1;
  background: transparent;
  overflow: hidden;

  .ds_buttons {
    // height: 3vw;
    bottom: 2%;
    width: 10%;
  }

  .right {
    margin-right: 1vw;
    float: right;
  }

  .left {
    margin-left: 1vw;
    float: left;
  }
}

.interstitial-timer-container.center {
  position: absolute;
  bottom: 28%;
  font-size: 2.4rem;

  .interstitial-timer {
    margin: 0;
  }
}

.banner-box {
  width: 88%;
  height: 94.5%;
  border-radius: 1vw;

  .inline {
    width: 90%;
  }

  .inline-timer-container {
    flex-direction: column;
    line-height: 1;
    position: absolute;
  }

  .inline-timer-container.left {
    left: 13%;
    top: 23%;
    transform: unset;
  }
  
  .inline-timer-container.center {
    top: 30%;
    left: 50%;
    transform: translate(-50%);
  }
  
  .inline-timer-container.right {
    right: 13%;
    top: 23%;
    transform: unset;
  }

  .inline-timer {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    letter-spacing: 3px;
  }

  .inline-timer-text-container {
    display: flex;
    justify-content: center;
  }

  .video-interstitial {
    width: 100%;
  }

  .top-image {
    height: 5vw;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 0.5vw 1vw;
  }

  .bottom-image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 4vw;
    margin-top: 0.5vw;
  }

  .button-image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 2vw;
    margin-bottom: 3vw;
    -webkit-animation: breathing 1.5s ease-out infinite normal;
    animation: breathing 1.5s ease-out infinite normal;
    cursor: pointer;
  }

  /*Flipper*/
  .flip-box {
    background-color: transparent;
    width: 100%;
    height: 100%;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    -ms-perspective: 1000px;
    -o-perspective: 1000px;
    border-radius: 1vw;
  }
  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 90%;
    text-align: center;
    transition: transform 1.3s;
    transform-style: preserve-3d;
  }
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-box:hover .flip-box-inner {
    transform: rotateY(180deg);
  }
  .flip-box-inner {
    width: 100%;
    height: 100%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }

  .front,
  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;

    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  .frontImg,
  .backImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;

    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  .back {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }

  .front:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: "";
    display: block;
    
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    border-radius: 1vw;
  }
  .flip-box-inner:hover .front,
  .flip-box-inner:hover .back {
    -webkit-transition: -webkit-transform 0s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: -webkit-transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
    -o-transition: transform 1.2s cubic-bezier(10.4, 0.2, 0.2, 1);
    transition: transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1),
      -webkit-transform 1.2s cubic-bezier(0.4, 0.2, 0.2, 1);
  }

  .back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .inner {
    height: 100%;
    -webkit-transform: translateY(-50%) translateZ(65px) scale(0.8);
    transform: translateY(-50%) translateZ(65px) scale(0.8);
    top: 45%;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 1px solid transparent;
    -webkit-perspective: inherit;
    perspective: inherit;
    z-index: 2;
  }

  .flip-box-inner .back {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .flip-box-inner .front {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .back {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }

  .backImg {
    scale: 1.15;
  }
  /*Flipper*/

  /*Scratch */
  .scratch_background,
  .scratch_removable {
    background-repeat: no-repeat;
  }

  .scratch_background {
    z-index: 100;
    height: 100%;
    background-size: 100% 100%;
  }

  .scratch_removable {
    z-index: 150;
    position: absolute;
    top: 10%;
    right: 4%;
    width: 90%;
    height: 25%;
    background-size: 100% 100%;
  }

  .scratch_movable_image {
    z-index: 799;
    top: 32%;
    position: absolute;
    // height: 25%;
    width: 35%;
    right: 10%;
    -webkit-animation: breathing 1.5s ease-out infinite normal;
    animation: breathing 1.5s ease-out infinite normal;
  }
  /*Scratch */

  /*Bounce Game */
  .bounce_top {
    display: flex;
    justify-content: space-between;
    width: 94%;
    height: 15%;
    margin-top: 5%;
  }

  .bounce_logo {
    object-fit: contain;
    height: 100%;
    width: 20%;
  }

  .bounce_timer_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bounce_timer {
    font-size: 200%;
  }

  .bounce_score_container {
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 20%;
    font-size: 200%;
    position: relative;
  }

  .bounce_score {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .bounce_poster {
    object-fit: contain;
    height: 45%;
    width: 100%;
  }

  .bounce_loading {
    object-fit: contain;
    height: 15%;
    width: 15%;
  }

  .bounce_bar {
    object-fit: contain;
    height: 10%;
    width: 50%;
  }
  /*Bounce Game */
}

.gallery .target {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gallery_banner {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 30%;
}

// swiper
.gallery-mySwiper {
  height: 55%;
  margin: unset !important;
  
  .swiper-wrapper {
    display: inline-flex;
    height: 85%;

    .swiper-slide {
      width: 0px;
    }
  }

  .swiper-slide {
    img {
      display: inline-block;
      width: 80%;
      height: 100%;
      object-fit: contain;
    }
  }

  .swiper-pagination {
    margin-top: 0.5vw;
  }

  .swiper-pagination-bullet {
    width: var(
      --swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 0.65vw)
    );
    height: var(
      --swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 0.65vw)
    );
    border-radius: 20px;
    margin: 0 2px;
    background: white;
    opacity: 0.6;
    border-color: black;
    border: 1px solid black;
    display: inline-block;
  }

  .swiper-pagination-bullet-active {
    background-color: black !important;
    opacity: 1;
  }
}

//swiper

.gallery-logo-footer {
  height: 10%;
  padding: 2% 0;
}

.gallery_logo {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100%;
}

.mobile-banner-video-container {
  display: inline-flex !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: space-between;
  background: white;
}

.mobile-banner-video-video-container {
  height: fit-content;
  position: relative;
}

.video-timer-banner-container {
  position: relative;
  display: inline-flex !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  align-items: center;
}

.video-timer-banner-video-container {
  position: absolute;
  top: 40%;
}

.video-timer-banner-timer-container {
  position: absolute;
  bottom: 5%;
  flex-direction: column;
  line-height: 1;
}

.video-timer-banner-timer-container.left {
  left: 13%;
  bottom: 13%;
}

.video-timer-banner-timer-container.right {
    right: 13%;
    bottom: 13%;
}

.video-timer-banner-timer {
  display: flex;
  font-size: 1.7vw;
  margin-bottom: 0;
  gap: 0.2vw;
}

.left .video-timer-banner-timer {
  font-size: 1vw;
}

.right .video-timer-banner-timer {
  font-size: 1vw;
}

.video-timer-banner-timer span::first-letter {
  letter-spacing: 5px;
}

.video-timer-banner-timer-text-container {
  display: flex;
  font-size: 0.7vw;
  justify-content: center;
  gap: 1.3vw;
}

.desktop-timer-banner-video-container {
  position: relative;
  position: absolute;
}

.desktop-timer-banner-btns-container {
  width: 100%;
}

.desktop-timer-banner-timer-container {
  flex-direction: column;
  line-height: 1;
}

.desktop-timer-banner-timer {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  letter-spacing: 3px;
}

.desktop-timer-banner-timer-text-container {
  display: flex;
  justify-content: center;
}

.creative-menu-btn svg {
  width: 2vw;
  height: 2vw;
}

.creative-menu-btn svg :hover {
  color: #0a4356;
}

.video-gallery .target {
  display: flex;
  align-items: flex-end;
  position: relative;

  #ds_div_buttons {
    z-index: 3;
  }
}

.video-gallery-video {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  object-fit: fill;
}

.video-gallery-swiper-container {
  height: 50%;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.video-gallery-arrow {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;
  background-color: white !important;
  color: black;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  line-height: 1.8em;
  font-family: cursive;
  border-radius: 5px;
  font-size: 1em;
  border: 1px solid;
  font-weight: 900;
  transition: all 1s ease;
  box-sizing: border-box;

  .arrow_down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-bottom: 3px;
    pointer-events: none;
  }
}

.video-gallery-mySwiper {
  height: 65%;
  width: 100%;
  z-index: 2;
  display: flex;
  align-items: self-end;
  .swiper-wrapper {
    display: inline-flex;
    height: 3.26vw;
    margin-left: 3.5vw;

    .swiper-slide {
      width: 0px;

      .slide-img {
        display: inline-block;
        width: 3vw;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.collect_or_evade_title {
  object-fit: contain;
  width: 50%;
  margin-top: 26%;
}

.collect_or_evade_subtitle {
  object-fit: contain;
  width: 60%;
  margin-top: 5%;
}

.collect_or_evade_timer {
  font-size: 2.7rem;
  margin: 0;
  margin-top: 2%;
  line-height: 1;
}

.collect_or_evade_movable {
  object-fit: contain;
  width: 40%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.collect_or_evade_arrow {
  object-fit: contain;
  width: 4%;
  position: absolute;
  bottom: 10%;
}

.left_arrow {
  left: 8%;
}

.right_arrow {
  right: 8%;
}

// program video
.program-video {
  width: 100%;
}

.program-video-grid {
  width: 100%;
  height: 100%;
  background-color: #1010105b;
  position: absolute;
  z-index: 97;
}

.program-video-grid-item {
  border: 0.01px solid white;
}

.program-video-grid-item.top-left {
  border-top-left-radius: 1vw;
}

.program-video-grid-item.bottom-right {
  border-bottom-right-radius: 1vw;
}

.program-video-grid-item.bottom-left {
  border-bottom-left-radius: 1vw;
}

.program-video-grid-item.top-right {
  border-top-right-radius: 1vw;
}

.half_video {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .half_video_title {
    object-fit: contain;
    width: 96%;
    padding-top: 2%;
  }

  & .half_video_subtitle {
    object-fit: contain;
    width: 96%;
  }

  & .half_video_daysUntilContainer {
    font-size: 1.5rem;
    margin: 2%;
  }

  & .half_video_video_container {
    position: relative;

    & .half_video_video {
      object-fit: cover;
      width: 100%;
    }
  }
}

// .button-image breathing style
@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
// .button-image breathing style

// game movable elements shaking animation

@-webkit-keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    -moz-transform: translate3d(-10px, 0, 0);
    -ms-transform: translate3d(-10px, 0, 0);
    -o-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    -moz-transform: translate3d(10px, 0, 0);
    -ms-transform: translate3d(10px, 0, 0);
    -o-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    -moz-transform: translate3d(-10px, 0, 0);
    -ms-transform: translate3d(-10px, 0, 0);
    -o-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    -moz-transform: translate3d(10px, 0, 0);
    -ms-transform: translate3d(10px, 0, 0);
    -o-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  -webkit-animation-duration: 10s;
  -moz-animation-duration: 10s;
  -o-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: shake;
  -moz-animation-name: shake;
  -o-animation-name: shake;
  animation-name: shake;
  animation-iteration-count: infinite;
}

// game movable elements shaking animation

// game loading spinner

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.spin_forever {
  animation: rotation 2s infinite linear;
}

// game loading spinner

// heart beat
.heartbeat {
  -webkit-animation: heartbeat 3s ease-in-out 5s infinite both;
  animation: heartbeat 3s ease-in-out 5s infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

// heart beat
