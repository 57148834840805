@import "../../Sass/variables";

.video-input-btn {
  padding: 0.6em 1em;
  border: 2px solid #23a4ce;
  border-radius: 8px;
  color: #23a4ce;
  font-weight: 700;
}

.ds-inputs {
  height: 2vw;
  min-width: 35px;
  cursor: pointer;
  display: inline-block;
  background-color: #ffffff;
  color: rgba($base-color, 1);
  fill: currentColor;
  outline: none;

  input {
    border: none;
    outline: none;
    box-sizing: border-box;
    padding-left: 1vw;
    -color: #888888;
  }

  &.ds-i-button {
    width: 2.1vw;
    border: 1px solid rgba($base-color, 0.5);
    -webkit-transition: 0.2s;
    transition: background-color 0.2s;
    box-sizing: border-box;
    vertical-align: bottom;
    // margin-left: 0.5vw;

    &:hover {
      color: rgba($highlight-color, 1);
    }

    label {
      height: 100%;
      width: 100%;
      cursor: pointer;

      svg {
        height: 100%;
        width: 100%;
        padding: 0.3vw;
        outline: none;
      }

      input {
        display: none;
      }
    }

    .icon {
      height: 100%;
      width: 100%;
      padding: 0.3vw;
      outline: none;
    }
  }

  &.ds-i-input {
    vertical-align: bottom;

    .icon-holder {
      border: 1px solid rgba($base-color, 0.5);
      height: 100%;
      width: 2.1vw;
      vertical-align: text-bottom;
      display: inline-block;
      border-radius: 40px 0 0 40px;

      &:hover {
        color: $highlight-color;
      }

      svg {
        height: 100%;
        width: 100%;
        padding: 0.3vw;
        outline: none;
      }
    }

    input {
      min-width: 100px;
      height: 2vw;
      border: 1px solid rgba($base-color, 0.5);
      border-left: none;
      vertical-align: text-bottom;
      border-radius: 0 40px 40px 0;
    }

    &.error {
      input {
        border-color: $error-color;
        color: $error-color;
      }

      .icon-holder {
        border-color: $error-color;
        color: $error-color;
      }
    }
  }

  &.ds-drop-down {
    width: 8vw;
    height: 2vw;
    border: 1px solid rgba($base-color, 0.5);
    box-sizing: border-box;
    outline: none;
    padding-left: 1vw;

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &.ds-number {
    min-width: 100px;
    height: 2vw;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    font-size: 1vw;

    input {
      width: calc(100% - 60px);
      padding: 0 10px;
      text-align: center;
      height: 100%;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      border-left: 1px solid rgba(0, 0, 0, 0.3);
      color: #656565;
      font-size: 18px;
    }

    .icons {
      width: 30px;
      height: 30px;
      margin-bottom: 4px;
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  &.ds-slider {
    height: 25px;
    background-color: transparent;

    input {
      -webkit-appearance: none;
      width: 100%;
      height: 25px;
      background-color: #6b818d;
      outline: none;
      -webkit-transition: 0.2s;
      transition: background-color 0.2s;
      padding: 0;

      &:hover {
        background-color: #8698a1;
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        background: $base-color;
        cursor: pointer;
        border-radius: 15px;
      }

      &::-moz-range-thumb {
        width: 25px;
        height: 25px;
        background: $base-color;
        cursor: pointer;
        border-radius: 15px;
      }
    }
  }

  &.ds-button {
    background-color: transparent;

    button {
      min-width: 70px;
      padding: 0 20px;
      text-align: center;
      height: 100%;
      border: 1px solid rgba(0, 0, 0, 0.3);
      color: #656565;
      font-size: 18px;
      background-color: #ffffff;
      border-radius: 3px;

      &:hover {
        background-color: #e9e9e9;
        border: 1px solid rgba(0, 0, 0, 0.5);
        color: #3b3b3b;
      }

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }

    &.colored-light {
      button {
        border: 1px solid rgba(30, 139, 175, 0.5);
        color: #ffffff;
        background-color: rgba($highlight-color, 1);

        &:hover {
          background-color: rgba($highlight-color, 0.9);
          border: 1px solid rgba(30, 139, 175, 0.2);
        }
      }
    }

    &.colored-dark {
      button {
        border: 1px solid rgba(30, 139, 175, 0.5);
        color: #ffffff;
        background-color: rgba($base-color, 1);

        &:hover {
          background-color: rgba($base-color, 0.9);
          border: 1px solid rgba($base-color, 1);
        }
      }
    }

    &.borderless {
      button {
        border: 1px solid rgba(0, 0, 0, 0);
      }
    }
  }

  &.ds-url-input {
    vertical-align: bottom;

    input {
      min-width: 100px;
      height: 2vw;
      font-size: 1vw;
      border: 1px solid rgba($base-color, 0.5);
      vertical-align: text-bottom;

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }

    &.error input {
      border-color: $error-color;
      color: $error-color;
    }
  }

  &.ds-txt-input,
  &.ds-num-input {
    vertical-align: bottom;

    input {
      min-width: 100px;
      height: 2vw;
      font-size: 1vw;
      border: 1px solid rgba($base-color, 0.5);
      vertical-align: text-bottom;

      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }

    &.error input {
      border-color: $error-color;
      color: $error-color;
    }
  }

  &.ds-num-input {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &.ds-image {
    width: 170px;
    height: 60px;
    border: 1px solid rgba(10, 67, 86, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    svg {
      background-color: rgba(255, 255, 255, 1);
      padding: 1px;
      height: 1.5vw;
      width: 1.5vw;
    }

    input {
      display: none;
    }
  }

  &.ds-video {
    width: 170px;
    height: 60px;
    border: 1px solid rgba(10, 67, 86, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    svg {
      background-color: rgba(255, 255, 255, 1);
      padding: 1px;
      height: 1.5vw;
      width: 1.5vw;
    }

    input {
      display: none;
    }
  }
}
