@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Heebo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arimo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Secular+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant&display=swap");
@import "./variables";

.App {
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #F1F6F9;
}

.main-content-container {
  width: 100%;
  min-height: calc(100vh - 70px);
  display: flex;
}

// @media screen and (min-width: 2000px) {
//   .App {
//     align-items: flex-start;
//   }

//   .main-content-container {
//     max-width: 1920px;
//   }
// }

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%; 
}

.__react_component_tooltip {
  background-color: $base-color !important;
}

.__react_component_tooltip.place-top::after {
  border-top-color: $base-color !important;
}

.n-input-override {
  .MuiOutlinedInput-input {
    width: 170px;
    box-sizing: border-box;
    height: 35px;
    padding: 5px 5px 5px 10px;
    margin: 0;
    color: #0a4356;
    border: 1px solid rgba(26, 67, 86, 0.5);
    background-color: #fff;
    border-radius: 10px;

    &.Mui-disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  fieldset {
    display: none;
  }
}

.margin-right-5 {
  margin-right: 5px;
}

.mr-20 {
  margin-right: 20px;
}

.box-shadow {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.ds-divider {
  height: 1px;
  width: 100%;
  background-color: $pop-up-inner-border;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #d1d1d1;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #d1d1d1;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #d1d1d1;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #d1d1d1;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #d1d1d1;
}

.disable-select {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

@import "../Components/StyleMenus/style";
//@import '../Components/StyleMenus/style';
